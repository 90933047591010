<template>
  <div>
    <search-and-table-part
      :pagination="pagination"
      @getItems="getItems"
      @trdbClicked="checkItem"
      :items="items"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :hasCheckDetail="true"
      :getTrigger="getTrigger"
    >
      <template slot="searchArea">
        <div class="row">
          <div class="col-12 col-md-6">
            <date-range-part
              :date="dateRange"
              :gridDegree="6"
              @dateChanged="dateChanged"
            />
          </div>
          <div class="col-12 col-md-6">
            <input-inline-part
              id="searchWebhookType"
              :labelText="`${$t('searchAndTablePart.webhooktype')}:`"
              :gridDegree="6"
            >
              <select
                id="searchWebhookType"
                class="form-control"
                v-model="searchDTO.WebhookType"
              >
                <option
                  v-for="webhookType in webhookTypes"
                  :key="webhookType.value"
                  :value="webhookType.value"
                >
                  {{ webhookType.text }}
                </option>
              </select>
            </input-inline-part>
          </div>
        </div>
      </template>
      <template slot="dataButtonAreaFront" slot-scope="props">
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="checkItem(items[props.index])"
        >
          <i class="fas fas fa-eye"></i>
        </button>
      </template>
    </search-and-table-part>
    <log-data
      :modalId="modalId"
      :item="item"
      :columns="columns"
      :isShow="isShow"
      @onClosed="onClosed"
    ></log-data>
  </div>
</template>

<script>
import searchAndTablePart from "@/components/parts/searchAndTablePart";
import dateRangePart from "@/components/parts/dateRangePart";
import inputInlinePart from "@/components/parts/inputInlinePart";
import logData from "@/components/datas/LogData";

import date from "@/filters/date";

export default {
  data() {
    return {
      items: [],
      item: {},
      columns: [
        { name: "CreateTime" },
        { name: "Id" },
        { name: "BusinessId" },
        { name: "DeviceId" },
        { name: "WebhookTypeText" },
        { name: "RequestURL" },
        { name: "ClientIP" },
        { name: "TransactionId" },
        { name: "IsSuccessText" },
        { name: "ErrorMsg" },
        { name: "Signature" },
      ],
      webhookTypes: this.$enum.webhookType.getAll(),
      searchDTO: {},
      pagination: {},
      idProperty: "Id",
      nameProperty: "",
      dateRange: {
        DayBegin: "",
        DayEnd: "",
      },
      isShow: false,
      modalId: "Webhook",
      getTrigger: false,
    };
  },
  components: {
    searchAndTablePart,
    dateRangePart,
    inputInlinePart,
    logData,
  },
  methods: {
    getItems(dTO) {
      this.searchDTO.DayBegin = this.dateRange.DayBegin;
      this.searchDTO.DayEnd = this.dateRange.DayEnd;
      const d = { ...dTO, ...this.searchDTO };
      this.$api.logs.getWebhookLogs(d).then((data) => {
        this.items = data.Datas;
        this.items.forEach((item) => {
          if (item.IsSuccess) {
            item.IsSuccessText = "是";
          } else {
            item.IsSuccessText = "否";
          }
          item.WebhookTypeText = this.$enum.webhookType.getByValue(
            item.WebhookType
          ).text;
        });
        this.pagination = data.Pagination;
        this.pagination = data.Pagination;
      });
    },
    checkItem(i) {
      this.$api.logs.getWebhookLog(i.Id).then((data) => {
        this.item = data;
        if (this.item.IsSuccess) {
          this.item.IsSuccessText = "是";
        } else {
          this.item.IsSuccessText = "否";
        }
        this.item.WebhookTypeText = this.$enum.webhookType.getByValue(
          this.item.WebhookType
        ).text;
        this.isShow = !this.isShow;
      });
    },
    onClosed() {
      this.isShow = !this.isShow;
    },
    dateChanged(begin, end) {
      this.dateRange.DayBegin = begin;
      this.dateRange.DayEnd = end;
    },
  },
};
</script>
